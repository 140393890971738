import middlewarePipeline from "@/router/middlewarePipeline";
import { createRouter, createWebHistory } from "vue-router";
import { authentication } from "./middleware/authentication";
import { checkRegion } from "./middleware/checkRegion";
import { checkRoutes } from "./middleware/checkRoutes";

import FilterRoutes from "./filterRoutes";
import { VALID_REGIONS } from "@/constants.js";
import { VueCookieNext } from "vue-cookie-next";

import { useUser } from "@/composables/useUser";
import { useWaitingRoom } from "@/composables/useWaitingRoom";

// Main page
const MainPage = () => import("@/views/wegow/main-pages/MainPage.vue");
const MainRegionPage = () =>
  import("@/views/wegow/main-pages/MainRegionPage.vue");

const LoginPage = () => import("@/views/wegow/main-pages/LoginPage.vue");
const WaitingRoom = () => import("@/views/events/WaitingRoom.vue");
const RegisterPage = () => import("@/views/wegow/main-pages/RegisterPage.vue");
const SignUpPromoter = () =>
  import("@/views/wegow/main-pages/PromoterRegister.vue");
const ChangePassword = () =>
  import("@/views/wegow/main-pages/ChangePassword.vue");
const UnsubscribeEmails = () =>
  import("@/views/wegow/main-pages/UnsubscribeEmails.vue");

const ProfilePage = () => import("@/views/wegow/user/ProfilePage.vue");
const CampaignPage = () => import("@/views/general/CampaignPage.vue");
const MyProfilePage = () => import("@/views/wegow/user/MyProfilePage.vue");
const WegowLiveMain = () => import("@/views/wegow/wegowLive/WegowLivePage.vue");
const WegowMoment = () => import("@/views/wegow/wegowMoments/WegowMoment.vue");
const ProfileSettingsPage = () =>
  import("@/views/wegow/user/ProfileSettingsPage.vue");
const NotificationsPage = () =>
  import("@/views/wegow/user/NotificationsPage.vue");
const MyTicketsPage = () => import("@/views/wegow/user/MyTicketsPage.vue");
const MyWeswapPage = () => import("@/views/wegow/user/MyWeswapPage.vue");

const BaseVenue = () => import("@/views/venues/BaseVenues.vue");
const VenueFilter = () => import("@/views/venues/VenueFilter.vue");
const VenueProfile = () => import("@/views/venues/VenueProfile.vue");

const BaseArtists = () => import("@/views/artists/BaseArtists.vue");
const ArtistFilter = () => import("@/views/artists/ArtistFilter.vue");
const ArtistProfile = () => import("@/views/artists/ArtistProfile.vue");
const ArtistCityProfile = () => import("@/views/artists/ArtistCityProfile.vue");

const BaseEvents = () => import("@/views/events/BaseEvents.vue");
const EventFilter = () => import("@/views/events/EventFilter.vue");
const EventProfile = () => import("@/views/events/EventProfile.vue");
const TicketOffice = () => import("@/views/events/TicketOffice.vue");
const ReportTicketOffice = () =>
  import("@/views/events/ReportTicketOffice.vue");

const PurchasePage = () => import("@/views/wegow/purchase/PurchasePage.vue");
const PurchaseCrossingPage = () =>
  import("@/views/wegow/purchase/PurchaseCrossingPage.vue");
const PaymentPage = () => import("@/views/wegow/payment/PaymentPage.vue");
const PaymentOfficePage = () =>
  import("@/views/wegow/payment/PaymentOfficePage.vue");
const ThankYouBase = () => import("@/views/wegow/purchase/ThankYouBase.vue");
const ThankYouPage = () => import("@/views/wegow/purchase/ThankYouPage.vue");
const ThankYouPageOffice = () =>
  import("@/views/wegow/purchase/ThankYouPageOffice.vue");

const BaseCompanies = () => import("@/views/companies/BaseCompanies.vue");
const CompanyPage = () => import("@/views/companies/CompanyPage.vue");

const BaseChat = () => import("@/views/chat/BaseChat.vue");
const ChatPage = () => import("@/views/chat/ChatPage.vue");

// Magazine page
const BaseMagazine = () => import("@/views/magazine/BaseMagazine.vue");
const MagazineMainPage = () =>
  import("@/views/magazine/main/MagazineMainPage.vue");
const TrendingPage = () =>
  import("@/views/magazine/categories/TrendingPage.vue");
const CuriositiesPage = () =>
  import("@/views/magazine/categories/CuriositiesPage.vue");
const FestivalsPage = () =>
  import("@/views/magazine/categories/FestivalsPage.vue");
const InternationalPage = () =>
  import("@/views/magazine/categories/InternationalPage.vue");
const PlaylistsPage = () =>
  import("@/views/magazine/categories/PlaylistsPage.vue");
const WegowLivePage = () =>
  import("@/views/magazine/categories/WegowLivePage.vue");
const GenresPage = () => import("@/views/magazine/categories/GenresPage.vue");
const PostPage = () => import("@/views/magazine/PostPage.vue");

// General pages
const ContactWithUs = () => import("@/views/general/ContactWithUs");
const HelpPage = () => import("@/views/general/HelpPage");
const NotFound = () => import("@/views/general/NotFound");
const DownloadAppPage = () => import("@/views/general/DownloadAppPage");
const NotFoundObject = () => import("@/views/general/NotFoundObject");
const HowItWorksPage = () => import("@/views/general/HowItWorksPage");
const TermsOfUsePage = () => import("@/views/general/TermsOfUsePage");
const CookiesPage = () => import("@/views/general/CookiesPage");
const PrivacyPolicyPage = () => import("@/views/general/PrivacyPolicyPage");
const EventValidation = () => import("@/views/events/EventValidation");
const TestingGooglePixelPage = () =>
  import("@/views/general/TestingGooglePixelPage");
const SpotifyConnected = () =>
  import("@/views/wegow/user/SpotifyConnected.vue");

// My Ticket pages
const MyTicketLogin = () => import("@/views/my-ticket/MyTicketLogin.vue");
const MyTicket = () => import("@/views/my-ticket/MyTicket.vue");
const MyTicketModify = () => import("@/views/my-ticket/MyTicketModify.vue");
const MyTicketTransfer = () => import("@/views/my-ticket/MyTicketTransfer.vue");
const MyTicketHistory = () => import("@/views/my-ticket/MyTicketHistory.vue");
const MyTicketPayment = () => import("@/views/my-ticket/MyTicketPayment.vue");
const MyTicketUpgrade = () => import("@/views/my-ticket/MyTicketUpgrade.vue");
const MyTicketComplements = () =>
  import("@/views/my-ticket/MyTicketComplements.vue");
const ThankYouMyTicket = () => import("@/views/my-ticket/ThankYouMyTicket.vue");
// Nueva pagina de sala
const ProVenue = () => import("@/views/venues/ProVenue.vue");

export const routes = [
  {
    path: "/",
    name: "home",
    component: MainPage,
    meta: {
      middleware: [checkRegion],
      // TODO: Mirar como poner distintos idiomas (con el codigo de la traduccion?)
      title: "Entradas de conciertos y festivales | Wegow",
    },
  },
  {
    path: "/unsubscribe",
    name: "UnsubscribeEmails",
    component: UnsubscribeEmails,
    meta: {
      title: "Cancelar suscripción | Wegow",
      description:
        "En Wegow comprar entradas para conciertos o festivales es muy sencillo ✔ Mantente informado sobre tus artistas y eventos musicales favoritos",
    },
  },
  {
    path: "/:region/waiting-room",
    children: [
      {
        path: ":slug",
        name: "WaitingRoom",
        component: WaitingRoom,
        meta: {
          title: "Cola | Wegow",
          description: "",
        },
        props: true,
      },
      {
        path: "compra/:slug",
        name: "WaitingRoomPurchasePage",
        component: PurchasePage,
        alias: [
          "kauf/:slug", // de
          "purchase/:slug", // en
          "achat/:slug", // fr
          "acquisto/:slug", // it
          "compra/:slug", // pt
        ],
        meta: {
          middleware: [checkRoutes],
        },
        beforeEnter: async (to, _from, next) => {
          const $cookie = VueCookieNext;
          const { token, tokenInfo, getToken, checkToken } = useWaitingRoom();

          token.value ||= $cookie.getCookie("wegow.wr-token");
          token.value ||= await getToken(to.params.slug);
          await checkToken(to.params.slug, token.value);

          if (tokenInfo.value?.call_date) return next();

          return next({
            name: "WaitingRoom",
            params: {
              slug: to.params.slug,
              region: to.params.region,
            },
            query: to.query,
          });
        },
      },
      {
        path: "pago/:reference",
        name: "WaitingRoomPaymentPage",
        props: (route) => ({
          reference: route.params.reference,
          f2f: route.query.f2f === "true",
        }),
        component: PaymentPage,
        alias: [
          "zahlung/:reference", // de
          "payment/:reference", // en
          "paiement/:reference", // fr
          "pagamento/:reference", // it
          "pagamento/:reference", // pt
        ],
      },
      {
        path: "gracias/:reference",
        name: "WaitingRoomThankYouPage",
        component: ThankYouBase,
        props: (route) => ({
          reference: route.params.reference,
          f2f: route.query.f2f === "true",
        }),
        alias: [
          "danke/:reference", // de
          "thank-you/:reference", // en
          "merci/:reference", // fr
          "grazie/:reference", // it
          "obrigado/:reference", // pt
        ],
        meta: {
          middleware: [checkRoutes],
        },
      },
    ],
  },
  {
    path: "/:region/spotify-connected",
    name: "SpotifyConnected",
    component: SpotifyConnected,
    meta: {
      title: "Spotify connected | Wegow",
      description:
        "En Wegow comprar entradas para conciertos o festivales es muy sencillo ✔ Mantente informado sobre tus artistas y eventos musicales favoritos",
    },
  },
  {
    name: "NotFound",
    path: "/:region/pagina-no-encontrada",
    alias: [
      // TODO: hacer traducciones not found
      "/:region/not-found", // en
    ],
    component: NotFoundObject,
    meta: {
      title: "Página no encontrada",
    },
  },
  {
    path: "/:region(" + VALID_REGIONS.join("|") + ")",
    name: "MainRegionPage",
    component: MainRegionPage,
    meta: {
      middleware: [checkRoutes],
      title: "Entradas de conciertos y festivales | Wegow España",
    },
  },
  ...FilterRoutes,
  {
    path: "/:region/entrar",
    name: "LoginPage",
    component: LoginPage,
    alias: [
      "/:region/login", // de
      "/:region/signin", // en
      "/:region/acceder", // fr
      "/:region/entrare", // it
      "/:region/iniciar-sessao", // pt
    ],
    meta: {
      middleware: [checkRoutes],
      title: "Iniciar Sesión | Wegow España",
    },
  },
  {
    path: "/:region/registrarse",
    name: "RegisterPage",
    component: RegisterPage,
    alias: [
      "/:region/registrieren", // de
      "/:region/register", // en
      "/:region/inscrire", // fr
      "/:region/registrarsi", // it
      "/:region/registrar-se", // pt
    ],
    meta: {
      middleware: [checkRoutes],
      title: "Registrate | Wegow España",
    },
  },
  {
    path: "/:region/registrarse-promotor",
    name: "SignUpPromoter",
    component: SignUpPromoter,
    alias: [
      "/:region/registrieren-projektträger", // de
      "/:region/register-promoter", // en
      "/:region/inscrire-promoteur", // fr
      "/:region/registrarsi-promotore", // it
      "/:region/registrar-se-promotor", // pt
    ],
    meta: {
      middleware: [checkRoutes],
      title: "Registrate promotor | Wegow España",
    },
  },
  {
    path: "/:region/contrasena-olvidada",
    name: "ChangePassword",
    component: ChangePassword,
    alias: [
      "/:region/passwort-vergessen", // de
      "/:region/forgot-password", // en
      "/:region/mot-de-passe", // fr
      "/:region/password-dimenticata", // it
      "/:region/esqueceu-se-da-palavra-passe", // pt
    ],
    meta: {
      middleware: [checkRoutes],
      title: "Cambiar contraseña | Wegow España",
    },
  },
  {
    path: "/:region/perfil/:slug",
    name: "ProfilePage",
    component: ProfilePage,
    alias: [
      "/:region/profil/:slug", // de
      "/:region/profile/:slug", // en
      "/:region/profil/:slug", // fr
      "/:region/profilo/:slug", // it
      "/:region/perfil/:slug", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/mi-perfil",
    name: "MyProfilePage",
    component: MyProfilePage,
    alias: [
      "/:region/mein-profil", // de
      "/:region/my-profile", // en
      "/:region/mon-profil", // fr
      "/:region/mio-profilo", // it
      "/:region/meu-perfil", // pt
    ],
    meta: {
      middleware: [checkRoutes, authentication],
    },
  },
  {
    path: "/:region/mis-entradas",
    name: "MyTicketsPage",
    component: MyTicketsPage,
    alias: [
      "/:region/meine-tickets", // de
      "/:region/my-tickets", // en
      "/:region/mes-billets", // fr
      "/:region/miei-biglietti", // it
      "/:region/meus-bilhetes", // pt
    ],
    meta: {
      middleware: [checkRoutes, authentication],
    },
  },
  {
    path: "/:region/ajustes",
    name: "ProfileSettingsPage",
    component: ProfileSettingsPage,
    alias: [
      "/:region/Einstellungen", // de
      "/:region/settings", // en
      "/:region/parametres", // fr
      "/:region/impostazioni", // it
      "/:region/definiciones", // pt
    ],
    meta: {
      middleware: [checkRoutes, authentication],
    },
  },
  {
    path: "/:region/notificaciones",
    name: "NotificationsPage",
    component: NotificationsPage,
    alias: [
      "/:region/benachrichtigungen", // de
      "/:region/notifications", // en
      "/:region/notifications", // fr
      "/:region/notifiche", // it
      "/:region/avisos", // pt
    ],
    meta: {
      middleware: [checkRoutes, authentication],
      title: "Notificaciones | Wegow",
    },
  },
  {
    path: "/:region/validacion/:validationCode",
    name: "EventValidation",
    component: EventValidation,
    alias: [],
    meta: {
      middleware: [checkRoutes, authentication],
    },
  },
  {
    path: "/:region/pixel-test/:gpixel",
    name: "TestingGooglePixelPage",
    component: TestingGooglePixelPage,
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/mis-entradas-weswap",
    name: "MyWeswapPage",
    component: MyWeswapPage,
    alias: [
      "/:region/meine-tickets-weswap", // de
      "/:region/my-tickets-weswap", // en
      "/:region/mes-billets-weswap", // fr
      "/:region/miei-biglietti-weswap", // it
      "/:region/meus-bilhetes-weswap", // pt
    ],
    meta: {
      middleware: [checkRoutes, authentication],
    },
  },
  {
    path: "/:region/paginas",
    name: "BaseCompanies",
    component: BaseCompanies,
    alias: [],
    meta: {
      middleware: [checkRoutes],
      title: "WEGOW | Páginas",
    },
    children: [
      {
        path: "/:region/paginas/:slug",
        name: "CompanyPage",
        component: CompanyPage,
        // TODO: alias para las paginas
        alias: [
          "/:region/seiten/:slug", // de
          "/:region/pages/:slug", // en
          "/:region/pages/:slug", // fr
          "/:region/pagine/:slug", // it
          "/:region/paginas/:slug", // pt
        ],
      },
    ],
  },
  {
    path: "/:region/chat",
    name: "BaseChat",
    component: BaseChat,
    alias: [],
    meta: {
      middleware: [checkRoutes, authentication],
      title: "WEGOW | Chat",
    },
    children: [
      {
        path: "/:region/chat",
        name: "ChatPage",
        component: ChatPage,
        alias: [
          "/:region/chat", // de
          "/:region/chat", // en
          "/:region/chat", // fr
          "/:region/chat", // it
          "/:region/chat", // pt
        ],
      },
    ],
  },
  {
    path: "/:region/recintos",
    name: "BaseVenue",
    component: BaseVenue,
    alias: [],
    meta: {
      middleware: [checkRoutes],
    },
    children: [
      {
        path: "/:region/recintos",
        name: "VenueFilter",
        component: VenueFilter,
        alias: [
          "/:region/veranstaltungsorte", // de
          "/:region/venues", // en
          "/:region/salles", // fr
          "/:region/locali", // it
          "/:region/locais", // pt
        ],
        meta: {
          title: "Wegow - Salas",
        },
      },
      {
        path: "/:region/recintos/:slug",
        name: "VenueProfile",
        component: ProVenue,
        alias: [
          "/:region/veranstaltungsorte/:slug", // de
          "/:region/venues/:slug", // en
          "/:region/salles/:slug", // fr
          "/:region/locali/:slug", // it
          "/:region/locais/:slug", // pt
        ],
        beforeEnter: async (to, from, next) => {
          if (to.params.slug === "geo") {
            next({
              name: "FiltersPage",
              params: {
                region: to.params.region,
                type: to.path.split("/")[2],
              },
            });
          } else {
            next();
          }
        },
        meta: {
          title: "Wegow - Salas",
        },
      },

      {
        path: "/:region/nr/recintos/:slug",
        name: "VenueNRProfile",
        component: VenueProfile,
        alias: [
          "/:region/nr/veranstaltungsorte/:slug", // de
          "/:region/nr/venues/:slug", // en
          "/:region/nr/salles/:slug", // fr
          "/:region/nr/locali/:slug", // it
          "/:region/nr/locais/:slug", // pt
        ],
        meta: {
          title: "Wegow - Salas",
        },
      },
    ],
  },
  {
    path: "/:region/artistas",
    name: "BaseArtists",
    component: BaseArtists,
    alias: [],
    meta: {
      middleware: [checkRoutes],
    },
    children: [
      {
        path: "/:region/artistas",
        name: "ArtistFilter",
        component: ArtistFilter,
        alias: [
          "/:region/kuenstler", // de
          "/:region/artists", // en
          "/:region/artistes", // fr
          "/:region/artisti", // it
          "/:region/artistas", // pt
        ],
        meta: {
          title: "Wegow - Artistas",
        },
      },
      {
        path: "/:region/artistas/:slug",
        name: "ArtistProfile",
        component: ArtistProfile,
        alias: [
          "/:region/kuenstler/:slug", // de
          "/:region/artists/:slug", // en
          "/:region/artistes/:slug", // fr
          "/:region/artisti/:slug", // it
          "/:region/artistas/:slug", // pt
        ],
        beforeEnter: async (to, from, next) => {
          if (to.params.slug === "geo") {
            next({
              name: "FiltersPage",
              params: {
                region: to.params.region,
                type: to.path.split("/")[2],
              },
            });
          } else {
            next();
          }
        },
        meta: {
          title: "Wegow - Artistas",
        },
      },
      {
        path: "/:region/nr/artistas/:slug",
        name: "ArtistNRProfile",
        component: ArtistProfile,
        alias: [
          "/:region/nr/kuenstler/:slug", // de
          "/:region/nr/artists/:slug", // en
          "/:region/nr/artistes/:slug", // fr
          "/:region/nr/artisti/:slug", // it
          "/:region/nr/artistas/:slug", // pt
        ],
        meta: {
          title: "Wegow - Artistas",
        },
      },
      {
        path: "/:region/artistas/:slug/:city",
        name: "ArtistCityProfile",
        component: ArtistCityProfile,
        alias: [
          "/:region/kuenstler/:slug/:city", // de
          "/:region/artists/:slug/:city", // en
          "/:region/artistes/:slug/:city", // fr
          "/:region/artisti/:slug/:city", // it
          "/:region/artistas/:slug/:city", // pt
        ],
        beforeEnter: async (to, from, next) => {
          if (to.params.slug === "geo") {
            next({
              name: "FiltersPageCountry",
              params: {
                region: to.params.region,
                type: to.path.split("/")[2],
                country: to.params.city,
              },
            });
          } else {
            next();
          }
        },
        meta: {
          title: "Wegow - Artistas",
        },
      },
      {
        path: "/:region/nr/artistas/:slug/:city",
        name: "ArtistNRCityProfile",
        component: ArtistCityProfile,
        alias: [
          "/:region/nr/kuenstler/:slug/:city", // de
          "/:region/nr/artists/:slug/:city", // en
          "/:region/nr/artistes/:slug/:city", // fr
          "/:region/nr/artisti/:slug/:city", // it
          "/:region/nr/artistas/:slug/:city", // pt
        ],
        meta: {
          title: "Wegow - Artistas",
        },
      },
    ],
  },
  {
    path: "/:region/conciertos",
    name: "BaseEvents",
    component: BaseEvents,
    alias: [],
    meta: {
      middleware: [checkRoutes],
    },
    children: [
      {
        path: "/:region/conciertos",
        name: "EventFilter",
        component: EventFilter,
        alias: [
          "/:region/konzerte", // de
          "/:region/concerts", // en
          "/:region/concerts", // fr
          "/:region/concerti", // it
          "/:region/concertos", // pt
        ],
        // meta: {
        //   title: "Wegow - eventos"
        // }
      },
      {
        path: "/:region/conciertos/:slug",
        name: "EventProfile",
        component: EventProfile,
        alias: [
          "/:region/konzerte/:slug", // de
          "/:region/concerts/:slug", // en
          "/:region/concerts/:slug", // fr
          "/:region/concerti/:slug", // it
          "/:region/concertos/:slug", // pt
        ],
        meta: {},
        beforeEnter: async (to, from, next) => {
          if (to.params.slug === "geo") {
            next({
              name: "FiltersPage",
              params: {
                region: to.params.region,
                type: to.path.split("/")[2],
              },
            });
          } else {
            next();
          }
        },
        // meta: {
        //   title: "Wegow - eventos"
        // }
      },
      {
        path: "/:region/nr/conciertos/:slug",
        name: "EventNRProfile",
        component: EventProfile,
        alias: [
          "/:region/nr/konzerte/:slug", // de
          "/:region/nr/concerts/:slug", // en
          "/:region/nr/concerts/:slug", // fr
          "/:region/nr/concerti/:slug", // it
          "/:region/nr/concertos/:slug", // pt
        ],
        meta: {},
        // meta: {
        //   title: "Wegow - eventos"
        // }
      },
    ],
  },
  {
    path: "/:region/festivales",
    name: "BaseFestival",
    component: BaseEvents,
    alias: [],
    meta: {
      middleware: [checkRoutes],
    },
    children: [
      {
        path: "/:region/festivales",
        name: "FestivalFilter",
        component: EventFilter,
        alias: [
          "/:region/festivals", // de
          "/:region/festivals", // en
          "/:region/festivals", // fr
          "/:region/festival", // it
          "/:region/festivais", // pt
        ],
        // meta: {
        //   title: "Wegow - eventos"
        // }
      },
      {
        path: "/:region/festivales/:slug",
        name: "FestivalProfile",
        component: EventProfile,
        alias: [
          "/:region/festivals/:slug", // de
          "/:region/festivals/:slug", // en
          "/:region/festivals/:slug", // fr
          "/:region/festival/:slug", // it
          "/:region/festivais/:slug", // pt
        ],
        meta: {},
        beforeEnter: async (to, from, next) => {
          if (to.params.slug === "geo") {
            next({
              name: "FiltersPage",
              params: {
                region: to.params.region,
                type: to.path.split("/")[2],
              },
            });
          } else {
            next();
          }
        },
        // meta: {
        //   title: "Wegow - eventos"
        // }
      },
      {
        path: "/:region/nr/festivales/:slug",
        name: "FestivalNRProfile",
        component: EventProfile,
        meta: {},
        alias: [
          "/:region/nr/festivals/:slug", // de
          "/:region/nr/festivals/:slug", // en
          "/:region/nr/festivals/:slug", // fr
          "/:region/nr/festival/:slug", // it
          "/:region/nr/festivais/:slug", // pt
        ],
        // meta: {
        //   title: "Wegow - eventos"
        // }
      },
    ],
  },
  {
    path: "/:region/fiestas",
    name: "BaseParty",
    component: BaseEvents,
    alias: [],
    meta: {
      middleware: [checkRoutes],
    },
    children: [
      {
        path: "/:region/fiestas",
        name: "PartyFilter",
        component: EventFilter,
        alias: [
          "/:region/parteien", // de
          "/:region/parties", // en
          "/:region/fetes", // fr
          "/:region/feste", // it
          "/:region/festas", // pt
        ],
        // meta: {
        //   title: "Wegow - eventos"
        // }
      },
      {
        path: "/:region/fiestas/:slug",
        name: "PartyProfile",
        component: EventProfile,
        alias: [
          "/:region/parteien/:slug", // de
          "/:region/parties/:slug", // en
          "/:region/fetes/:slug", // fr
          "/:region/feste/:slug", // it
          "/:region/festas/:slug", // pt
        ],
        meta: {},
        beforeEnter: async (to, from, next) => {
          if (to.params.slug === "geo") {
            next({
              name: "FiltersPage",
              params: {
                region: to.params.region,
                type: to.path.split("/")[2],
              },
            });
          } else {
            next();
          }
        },
        // meta: {
        //   title: "Wegow - eventos"
        // }
      },
      {
        path: "/:region/nr/fiestas/:slug",
        name: "PartyNRProfile",
        component: EventProfile,
        meta: {},
        alias: [
          "/:region/nr/parteien/:slug", // de
          "/:region/nr/parties/:slug", // en
          "/:region/nr/fetes/:slug", // fr
          "/:region/nr/feste/:slug", // it
          "/:region/nr/festas/:slug", // pt
        ],
        // meta: {
        //   title: "Wegow - eventos"
        // }
      },
    ],
  },
  {
    path: "/:region/sesiones",
    name: "BaseSession",
    component: BaseEvents,
    alias: [],
    meta: {
      middleware: [checkRoutes],
    },
    children: [
      {
        path: "/:region/sesiones",
        name: "SessionFilter",
        component: EventFilter,
        alias: [
          "/:region/sitzungen", // de
          "/:region/sessions", // en
          "/:region/seances", // fr
          "/:region/sessioni", // it
          "/:region/sessoes", // pt
        ],
        // meta: {
        //   title: "Wegow - eventos"
        // }
      },
      {
        path: "/:region/sesiones/:slug",
        name: "SessionProfile",
        component: EventProfile,
        alias: [
          "/:region/sitzungen/:slug", // de
          "/:region/sessions/:slug", // en
          "/:region/seances/:slug", // fr
          "/:region/sessioni/:slug", // it
          "/:region/sessoes/:slug", // pt
        ],
        meta: {},
        beforeEnter: async (to, from, next) => {
          if (to.params.slug === "geo") {
            next({
              name: "FiltersPage",
              params: {
                region: to.params.region,
                type: to.path.split("/")[2],
              },
            });
          } else {
            next();
          }
        },
        // meta: {
        //   title: "Wegow - eventos"
        // }
      },
      {
        path: "/:region/nr/sesiones/:slug",
        name: "SessionNRProfile",
        component: EventProfile,
        meta: {},
        alias: [
          "/:region/sitzungen/:slug", // de
          "/:region/sessions/:slug", // en
          "/:region/seances/:slug", // fr
          "/:region/sessioni/:slug", // it
          "/:region/sessoes/:slug", // pt
        ],
        // meta: {
        //   title: "Wegow - eventos"
        // }
      },
    ],
  },
  {
    path: "/:region/wegow-live",
    name: "WegowLiveMain",
    component: WegowLiveMain,
    alias: [
      "/:region/wegow-live", // de
      "/:region/wegow-live", // en
      "/:region/wegow-live", // fr
      "/:region/wegow-live", // it
      "/:region/wegow-live", // pt
    ],
    meta: {
      title: "WegowLive",
    },
  },
  {
    path: "/:region/wegowmoments/:id",
    name: "WegowMoment",
    component: WegowMoment,
    alias: [
      "/:region/wegowmoments/:id", // de
      "/:region/wegowmoments/:id", // en
      "/:region/wegowmoments/:id", // fr
      "/:region/wegowmoments/:id", // it
      "/:region/wegowmoments/:id", // pt
    ],
    meta: {
      title: "WegowMoment",
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/taquilla",
    name: "TicketOfficePage",
    component: TicketOffice,
    alias: [
      "/:region/ticketschalter", // de
      "/:region/ticket-office", // en
      "/:region/billetterie", // fr
      "/:region/biglietteria", // it
      "/:region/bilheteira", // pt
    ],
    meta: {
      middleware: [checkRoutes, authentication],
    },
  },
  {
    path: "/:region/reporte-taquilla/:id",
    name: "ReportTicketOfficePage",
    component: ReportTicketOffice,
    alias: [
      "/:region/Kassenbericht/:id", // de
      "/:region/ticket-office-report/:id", // en
      "/:region/rapport-billetterie/:id", // fr
      "/:region/resoconto-biglietteria/:id", // it
      "/:region/relatorio-bilheteira/:id", // pt
    ],
    meta: {
      middleware: [checkRoutes, authentication],
    },
  },
  {
    path: "/:region/compra/:slug",
    name: "PurchasePage",
    component: PurchasePage,
    alias: [
      "/:region/kauf/:slug", // de
      "/:region/purchase/:slug", // en
      "/:region/achat/:slug", // fr
      "/:region/acquisto/:slug", // it
      "/:region/compra/:slug", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/productos-adicionales/:reference",
    name: "PurchaseCrossingPage",
    props: (route) => ({
      reference: route.params.reference,
      f2f: route.query.f2f === "true",
    }),
    component: PurchaseCrossingPage,
    alias: [
      "/:region/zusätzliche-produkte/:reference", // de
      "/:region/additional-products/:reference", // en
      "/:region/produits-supplémentaires/:reference", // fr
      "/:region/prodotti-aggiuntivi/:reference", // it
      "/:region/produtos-adicionais/:reference", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/widget/productos-adicionales/:reference",
    name: "PurchaseCrossingPageWidget",
    props: (route) => ({
      reference: route.params.reference,
      f2f: route.query.f2f === "true",
    }),
    component: PurchaseCrossingPage,
    alias: [
      "/:region/widget/zusätzliche-produkte/:reference", // de
      "/:region/widget/additional-products/:reference", // en
      "/:region/widget/produits-supplémentaires/:reference", // fr
      "/:region/widget/prodotti-aggiuntivi/:reference", // it
      "/:region/widget/produtos-adicionais/:reference", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/taquilla/:slug",
    name: "PurchaseOfficePage",
    component: PurchasePage,
    alias: [
      "/:region/ticketschalter/:slug", // de
      "/:region/ticket-office/:slug", // en
      "/:region/billetterie/:slug", // fr
      "/:region/biglietteria/:slug", // it
      "/:region/bilheteira/:slug", // pt
    ],
    meta: {
      middleware: [checkRoutes, authentication],
    },
  },
  {
    path: "/:region/widget/:slug",
    name: "PurchasePageWidget",
    component: PurchasePage,
    alias: [
      "/:region/widget/:slug", // de
      "/:region/widget/:slug", // en
      "/:region/widget/:slug", // fr
      "/:region/widget/:slug", // it
      "/:region/widget/:slug", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/widget/:slug",
    name: "PurchasePageWidgetNoRegion",
    component: PurchasePage,
    alias: [
      "/widget/:slug", // de
      "/widget/:slug", // en
      "/widget/:slug", // fr
      "/widget/:slug", // it
      "/widget/:slug", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
    beforeEnter: async (to, from, next) => {
      next({
        name: "PurchasePageWidget",
        params: {
          region: "es",
          slug: to.params.slug,
        },
      });
    },
  },
  {
    path: "/:region/pago/:reference",
    name: "PaymentPage",
    props: (route) => ({
      reference: route.params.reference,
      f2f: route.query.f2f === "true",
    }),
    component: PaymentPage,
    alias: [
      "/:region/zahlung/:reference", // de
      "/:region/payment/:reference", // en
      "/:region/paiement/:reference", // fr
      "/:region/pagamento/:reference", // it
      "/:region/pagamento/:reference", // pt
    ],
  },
  {
    path: "/:region/pago-taquilla/:reference",
    name: "PaymentOfficePage",
    props: (route) => ({
      reference: route.params.reference,
      f2f: route.query.f2f === "true",
    }),
    component: PaymentOfficePage,
    alias: [
      "/:region/kassenzahlung/:reference", // de
      "/:region/ticket-office-payment/:reference", // en
      "/:region/paiement-billetterie/:reference", // fr
      "/:region/pagamento-biglietteria/:reference", // it
      "/:region/pagamento-bilheteira/:reference", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/widget/pago/:reference",
    name: "PaymentPageWidget",
    props: (route) => ({
      reference: route.params.reference,
      f2f: route.query.f2f === "true",
    }),
    component: PaymentPage,
    alias: [
      "/:region/widget/zahlung/:reference", // de
      "/:region/widget/payment/:reference", // en
      "/:region/widget/paiement/:reference", // fr
      "/:region/widget/pagamento/:reference", // it
      "/:region/widget/pagamento/:reference", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/gracias-taquilla/:reference",
    name: "ThankYouPageOffice",
    component: ThankYouPageOffice,
    alias: [
      "/:region/danke-ticketschalter/:reference", // de
      "/:region/thank-you-ticket-office/:reference", // en
      "/:region/merci-billetterie/:reference", // fr
      "/:region/grazie-biglietteria/:reference", // it
      "/:region/obrigado-bilheteira/:reference", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/gracias/:reference",
    name: "ThankYouPage",
    component: ThankYouPage,
    alias: [
      "/:region/danke/:reference", // de
      "/:region/thank-you/:reference", // en
      "/:region/merci/:reference", // fr
      "/:region/grazie/:reference", // it
      "/:region/obrigado/:reference", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/widget/gracias/:reference",
    name: "ThankYouPageWidget",
    component: ThankYouPage,
    alias: [
      "/:region/widget/danke/:reference", // de
      "/:region/widget/thank-you/:reference", // en
      "/:region/widget/merci/:reference", // fr
      "/:region/widget/grazie/:reference", // it
      "/:region/widget/obrigado/:reference", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/magazine",
    name: "BaseMagazine",
    component: BaseMagazine,
    alias: [
      "/:region/magazine", // de
      "/:region/magazine", // en
      "/:region/magazine", // fr
      "/:region/magazine", // it
      "/:region/magazine", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
    children: [
      {
        path: "/:region/magazine",
        name: "MagazineMainPage",
        component: MagazineMainPage,
        alias: [
          "/:region/magazine", // de
          "/:region/magazine", // en
          "/:region/magazine", // fr
          "/:region/magazine", // it
          "/:region/magazine", // pt
        ],
        meta: {
          title: "WegowMag",
          description:
            "Si quieres mantenerte al día con las mejores noticias sobre música, entonces WegowMag es tu sitio ✔️ ¡Bienvenido, porque la música también se lee!",
        },
      },
      {
        path: "/:region/magazine/pagina-no-encontrada",
        name: "NotFoundMag",
        component: NotFound,
        alias: [
          "/:region/magazine/seite-nicht-gefunden", // de
          "/:region/magazine/page-not-found", // en
          "/:region/magazine/page-not-found", // fr
          "/:region/magazine/pagina-non-trovata", // it
          "/:region/magazine/pagina-nao-encontrada", // pt
        ],
        meta: {
          title: "WegowMag",
        },
      },
      {
        path: "/:region/magazine/trending",
        name: "TrendingPage",
        component: TrendingPage,
        alias: [
          "/:region/magazine/trending", // de
          "/:region/magazine/trending", // en
          "/:region/magazine/trending", // fr
          "/:region/magazine/trending", // it
          "/:region/magazine/trending", // pt
        ],
        meta: {
          title: "Trending",
        },
      },
      {
        path: "/:region/magazine/curiosidades",
        name: "CuriositiesPage",
        component: CuriositiesPage,
        alias: [
          "/:region/magazine/curiosities", // de
          "/:region/magazine/curiosities", // en
          "/:region/magazine/curiosities", // fr
          "/:region/magazine/curiosities", // it
          "/:region/magazine/curiosities", // pt
        ],
        meta: {
          title: "Curiosidades",
        },
      },
      {
        path: "/:region/magazine/festivales",
        name: "FestivalsPage",
        component: FestivalsPage,
        alias: [
          "/:region/magazine/festivals", // de
          "/:region/magazine/festivals", // en
          "/:region/magazine/festivals", // fr
          "/:region/magazine/festivals", // it
          "/:region/magazine/festivals", // pt
        ],
        meta: {
          title: "Festivales",
        },
      },
      {
        path: "/:region/magazine/internacional",
        name: "InternationalPage",
        component: InternationalPage,
        alias: [
          "/:region/magazine/international", // de
          "/:region/magazine/international", // en
          "/:region/magazine/international", // fr
          "/:region/magazine/international", // it
          "/:region/magazine/international", // pt
        ],
        meta: {
          title: "Internacional",
        },
      },
      {
        path: "/:region/magazine/playlists",
        name: "PlaylistsPage",
        component: PlaylistsPage,
        alias: [
          "/:region/magazine/playlists", // de
          "/:region/magazine/playlists", // en
          "/:region/magazine/playlists", // fr
          "/:region/magazine/playlists", // it
          "/:region/magazine/playlists", // pt
        ],
        meta: {
          title: "Playlists",
        },
      },
      {
        path: "/:region/magazine/wegowlive",
        name: "WegowLivePage",
        component: WegowLivePage,
        alias: [
          "/:region/magazine/wegowlive", // de
          "/:region/magazine/wegowlive", // en
          "/:region/magazine/wegowlive", // fr
          "/:region/magazine/wegowlive", // it
          "/:region/magazine/wegowlive", // pt
        ],
        meta: {
          title: "WegowLive",
        },
      },
      {
        path: "/:region/magazine/generos",
        name: "GenresPage",
        component: GenresPage,
        alias: [
          "/:region/magazine/genres", // de
          "/:region/magazine/genres", // en
          "/:region/magazine/genres", // fr
          "/:region/magazine/genres", // it
          "/:region/magazine/genres", // pt
        ],
        meta: {
          title: "Wegow - Géneros",
        },
      },
      {
        path: "/:region/magazine/:category/:slug",
        name: "PostPage",
        component: PostPage,
        alias: [
          "/:region/magazine/:category/:slug", // de
          "/:region/magazine/:category/:slug", // en
          "/:region/magazine/:category/:slug", // fr
          "/:region/magazine/:category/:slug", // it
          "/:region/magazine/:category/:slug", // pt
        ],
        meta: {
          title: "WegowMag - Publicaciones",
        },
      },
      {
        path: "/:region/magazine/:catchAll(.*)",
        name: "NotFoundMagGeneral",
        component: NotFound,
        meta: {
          title: "Página no encontrada",
        },
      },
    ],
  },
  {
    path: "/:region/promocion/:slug",
    name: "CampaignPage",
    component: CampaignPage,
    alias: [
      "/:region/kampagne/:slug", // de
      "/:region/campaign/:slug", // en
      "/:region/campagne/:slug", // fr
      "/:region/campagna/:slug", // it
      "/:region/campanha/:slug", // pt
    ],
    meta: {
      middleware: [checkRoutes, authentication],
    },
  },
  {
    path: "/:region/ayuda",
    name: "HelpPage",
    component: HelpPage,
    alias: [
      "/:region/hilfe", // de
      "/:region/help", // en
      "/:region/assistance", // fr
      "/:region/guida", // it
      "/:region/ajuda", // pt
    ],
    meta: {
      middleware: [checkRoutes],
      title: "Preguntas frecuentes | Wegow",
    },
  },
  {
    path: "/:region/contacta-con-nosotros",
    name: "ContactWithUs",
    component: ContactWithUs,
    alias: [
      "/:region/kontaktiere-uns", // de
      "/:region/contact-with-us", // en
      "/:region/contactez-nous", // fr
      "/:region/contatto-con-noi", // it
      "/:region/contato-conosco", // pt
    ],
    meta: {
      middleware: [checkRoutes],
      title: "Contacta con nosotros | Wegow",
    },
  },
  {
    path: "/:region/como-funciona",
    name: "HowItWorksPage",
    component: HowItWorksPage,
    alias: [
      "/:region/wie-funktionert-es", // de
      "/:region/how-it-works", // en
      "/:region/comment-ca-marche", // fr
      "/:region/come-funziona", // it
      "/:region/como-funciona", // pt
    ],
    meta: {
      middleware: [checkRoutes],
      title: "Cómo funciona | Wegow",
    },
  },
  {
    path: "/:region/terminos-y-condiciones",
    name: "TermsOfUsePage",
    component: TermsOfUsePage,
    alias: [
      "/:region/bedingungen-und-konditionen", // de
      "/:region/terms-and-conditions", // en
      "/:region/termes-et-conditions", // fr
      "/:region/termini-e-condizioni", // it
      "/:region/terms-e-condiçoes", // pt
    ],
    meta: {
      middleware: [checkRoutes],
      title: "Términos y condiciones de uso | Wegow",
    },
  },
  {
    path: "/:region/descargar-aplicacion",
    name: "DownloadAppPage",
    component: DownloadAppPage,
    alias: [
      "/:region/download-bewerbung", // de
      "/:region/download-app", // en
      "/:region/telecharger-application", // fr
      "/:region/scaricare-applicazione", // it
      "/:region/descarregar-aplicacao", // pt
    ],
    meta: {
      middleware: [checkRoutes],
      title: "👇 Descarga tu aplicación | Wegow",
    },
  },
  {
    path: "/:region/politica-de-cookies",
    name: "CookiesPage",
    component: CookiesPage,
    alias: [
      "/:region/cookie-richtlinie", // de
      "/:region/cookies-policy", // en
      "/:region/politique-de-cookies", // fr
      "/:region/politica-sui-cookie", // it
      "/:region/politica-de-cookies", // pt
    ],
    meta: {
      middleware: [checkRoutes],
      title: "Política de Cookies | Wegow",
    },
  },
  {
    path: "/:region/aviso-legal",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
    alias: [
      "/:region/rechtshinweis", // de
      "/:region/legal-notice", // en
      "/:region/avis-juridique", // fr
      "/:region/aviso-legal", // it
      "/:region/aviso-legal", // pt
    ],
    meta: {
      middleware: [checkRoutes],
      title: "Política legal | Wegow",
    },
  },
  // My Tickets routes
  {
    path: "/:region/mi-entrada-login",
    name: "MyTicketLogin",
    component: MyTicketLogin,
    alias: [
      "/:region/mein-ticket-login", // de
      "/:region/my-ticket-login", // en
      "/:region/mon-ticket-login", // fr
      "/:region/mio-ticket-login", // it
      "/:region/meu-ticket-login", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/mi-entrada",
    name: "MyTicket",
    component: MyTicket,
    alias: [
      "/:region/mein-ticket", // de
      "/:region/my-ticket", // en
      "/:region/mon-ticket", // fr
      "/:region/mio-ticket", // it
      "/:region/meu-ticket", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/modificar-mi-entrada",
    name: "MyTicketModify",
    component: MyTicketModify,
    alias: [
      "/:region/andern-mein-ticket", // de
      "/:region/modify-my-ticket", // en
      "/:region/modifier-mon-ticket", // fr
      "/:region/modificare-mio-ticket", // it
      "/:region/modificar-meu-ticket", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/transferir-mi-entrada",
    name: "MyTicketTransfer",
    component: MyTicketTransfer,
    alias: [
      "/:region/ubertragen-mein-ticket", // de
      "/:region/transfer-my-ticket", // en
      "/:region/transferer-mon-ticket", // fr
      "/:region/transferire-mio-ticket", // it
      "/:region/transferir-meu-ticket", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/historial-mi-entrada",
    name: "MyTicketHistory",
    component: MyTicketHistory,
    alias: [
      "/:region/verlauf-mein-ticket", // de
      "/:region/history-my-ticket", // en
      "/:region/historique-mon-ticket", // fr
      "/:region/cronologia-mio-ticket", // it
      "/:region/historico-meu-ticket", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/complementos-mi-entrada",
    name: "MyTicketComplements",
    component: MyTicketComplements,
    alias: [
      "/:region/erganzungen-mein-ticket", // de
      "/:region/complements-my-ticket", // en
      "/:region/complemes-mon-ticket", // fr
      "/:region/complementi-mio-ticket", // it
      "/:region/complementos-meu-ticket", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/mejorar-mi-entrada",
    name: "MyTicketUpgrade",
    component: MyTicketUpgrade,
    alias: [
      "/:region/Verbessern-mein-ticket", // de
      "/:region/upgrade-my-ticket", // en
      "/:region/ameliorer-mon-ticket", // fr
      "/:region/migliorare-mio-ticket", // it
      "/:region/melhorar-meu-ticket", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/pago-mi-entrada",
    name: "MyTicketPayment",
    component: MyTicketPayment,
    props: true,
    alias: [
      "/:region/zahlung-mein-ticket", // de
      "/:region/payment-my-ticket", // en
      "/:region/paiement-mon-ticket", // fr
      "/:region/pagamento-mio-ticket", // it
      "/:region/pagamento-meu-ticket", // pt
    ],
    meta: {
      middleware: [checkRoutes],
    },
  },
  {
    path: "/:region/thanks-ticket-management",
    name: "ThankYouMyTicket",
    component: ThankYouMyTicket,
    props: true,
    meta: {
      middleware: [checkRoutes],
    },
  },
  // ...FilterRoutes,
  {
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: {
      title: "Página no encontrada",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach(async (to, from, next) => {
  if (to.path.startsWith('/backoffice')) {
    const path = to.path.replace("/backoffice", "");
    const newUrl = `${process.env.VUE_APP_BACKOFFICE}${path}`;
    window.location.href = newUrl;
    return; // Salimos del guard para evitar más procesamiento
  }
  // TODO: revisar el store
  const { started, init, simpleInit, loadingInit } = useUser();
  // const { t } = useI18n();
  const { getIsWaitingRoom } = useWaitingRoom();
  if (to.path !== from.path) {
    if (to.meta?.title) {
      document.title = to.meta?.title;
    } else {
      document.title = "▷ Wegow Tickets | 2024";
    }
  }

  if (!started.value && !getIsWaitingRoom(to.name)) {
    if (to.path.includes("/widget/")) {
      await simpleInit();
    } else {
      await init();
    }
  } else {
    loadingInit.value = false;
  }

  if (!to.meta.middleware) {
    return next();
  }
  const { middleware } = to.meta;

  if (to.meta.breadcrumb) {
    // store.dispatch('breadcrumb/setBreadcrumb', to.meta.breadcrumb);
  } else {
    // store.dispatch('breadcrumb/resetBreadcrumbs');
  }

  const context = {
    to,
    from,
    next,
    // store,
    // permissions: to.meta.permission
  };

  router.afterEach((to, from, failure) => {
    window.scrollTo(0, 0);
  });

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
